<template>
  <v-dialog
    v-model="show"
    width="95%"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="dialogConfirm"
      persistent
      max-width="400px"
    >
      <v-form
        ref="form"
        v-model="validForm"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Confirmar Cita</span>
          </v-card-title>
          <v-card-text style="padding-bottom:0;">
            <v-container>
              <input
                type="hidden"
                :value="someData"
              >
              <v-row style="padding-bottom:20px;">
                Debe seleccionar una sala para confirmar la cita
              </v-row>
              <v-row>
                <v-select
                  v-model="salaConfirm"
                  :items="salasConfirm"
                  item-text="nombre"
                  item-value="id"
                  dense
                  outlined
                  required
                  :rules="[v => !!v || 'Seleccione una sala']"
                  label="Sala"
                />
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions style="padding-top:0;">
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="closeConfirm"
            >
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="saveConfirm"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card>
      <v-container fluid>
        <div>
          <!-- <v-row class="fill-height">
          <v-col> -->
          <v-sheet
            height="64"
          >
            <v-toolbar
              flat
              color="white"
            >
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.prev()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-col
                v-if="notarioId"
                cols="4"
              >
                <v-select
                  v-model="notario"
                  :items="notarios"
                  item-text="FullName"
                  item-value="id"
                  dense
                  outlined
                  hide-details
                  label="Notario"
                  @input="findAgendaByIdUSer"
                />
              </v-col>
              <v-col
                v-if="notarioId"
                cols="4"
              >
                <v-select
                  v-model="sala"
                  :items="salas"
                  item-text="nombre"
                  item-value="id"
                  dense
                  outlined
                  hide-details
                  label="Sala"
                  @input="findCitaByAgendaSala"
                />
              </v-col>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer />
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.next()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              id="calendarEdit"
              ref="calendar"
              v-model="value"
              color="indigo"
              type="week"
              locale="es"
              :dark="true"
              :events="events"
              :event-color="getEventColor"
              :first-interval="intervals.first"
              :interval-minutes="intervals.minutes"
              :interval-count="intervals.count"
              :interval-height="intervals.height"
              :weekdays="weekdays"
              :interval-style="intervalStyle"
              @mousedown:event="startDrag"
              @mousedown:time="startTime"
              @mousemove:time="mouseMove"
              @mouseup:time="endDrag"
            >
              <!-- @change="getEvents"
                :event-ripple="false"
                @mouseleave.native="cancelDrag"
                -->
              <!-- <template #event="{ event, timed, eventSummary }">
                  <div
                    class="v-event-draggable"
                    v-html="eventSummary"
                  />
                  <div
                    v-if="timed"
                    class="v-event-drag-bottom"
                    @mousedown.stop="extendBottom(event)"
                  />
                </template> -->
            </v-calendar>
          </v-sheet>
          <!-- </v-col>
        </v-row> -->
        </div>
      </v-container>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click.stop="show = false"
        >
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import AgendaApi from '@/services/api/Agenda'
  import UsuariosApi from '@/services/api/Usuarios'
  import MaestrosSalasApi from '@/services/api/MaestrosSalas'
  import EventBus from '../../../bus'

  const weekdaysDefault = [1, 2, 3, 4, 5]

  export default {
    name: 'AgendaComponent',
    props: {
      cita: {
        type: Object,
        default: () => (
          {
            id: '',
            procedimiento: {
              duracion: {
                tiempo_sala: '',
                tiempo_notario: '',
              },
            },
            estado: '',
            procedimientoName: '',
          }
        ),
      },
      visible: {
        type: Boolean,
      },
      id: {
        type: Number,
        default: 0,
      },
      cliente: {
        type: Number,
        default: 0,
      },
      client: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      value: '',
      clickCount: 0,
      someData: '',
      snackbar: false,
      notarioId: false,
      dialogConfirm: false,
      colorSnak: 'info',
      text: '',
      timeout: 3000,
      notario: '',
      sala: 0,
      salaConfirm: '',
      notarios: [],
      salas: [],
      salasConfirm: [],
      events: [],
      colors: ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createEvent1: null,
      createStart: null,
      endStart: null,
      extendOriginal: null,
      weekdays: weekdaysDefault,
      intervals: {
        first: 16,
        minutes: 30,
        count: 20,
        height: 48,
      },
      editItem: {
        id: '',
        inicio_dia: '',
        fin_dia: '',
        inicio_tarde: '',
        fin_tarde: '',
        citas: [],
        dates: [],
        days: [],
      },
      defaultItem: {
        id: '',
        inicio_dia: '',
        fin_dia: '',
        inicio_tarde: '',
        fin_tarde: '',
        citas: [],
        dates: [],
        days: [],
      },
    }),

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
            EventBus.$emit('update-agenda')
            EventBus.$emit('update-date')
          }
        },
      },
    },

    created () {
      // if (!this.client) {
      this.getNotarios()
      // } else {
      //   this.getCitasClientes(this.cliente)
      // }
    },

    methods: {
      startDrag ({ event, timed }) {
        if (event && timed) {
          this.dragEvent = event
          this.dragTime = null
          this.extendOriginal = null
        }
      },
      startTime (tms) {
        const mouse = this.toTime(tms)
        if (this.dragEvent && this.dragTime === null) {
          // const start = this.dragEvent.start
          this.dragTime = mouse
        } else {
          if (this.id === 0) {
            if (localStorage.getItem('role') !== 'ROLE_NOTARIO') {
              if (this.editItem.id != null && this.editItem.id !== '') {
                // eslint-disable-next-line no-unused-vars
                let edit = false

                const comparar = mouse.toISOString().substring(0, 10)
                // this.createStart = this.roundTime(mouse)
                this.editItem.dates.forEach(element => {
                  if (element.dia === comparar) {
                    edit = true
                  }
                })

                if (edit) {
                  this.text = 'El día seleccionado (' + comparar + ') está restingido'
                  this.colorSnak = 'info'
                  this.snackbar = true
                } else {
                  this.createStart = mouse.toISOString().substring(0, 16).replace('T', ' ')
                  var procedimiento = this.cita.procedimientoName

                  var valid = this.validHora(this.createStart)

                  if (valid === 0) {
                    this.text = 'La hora seleccionada se encuentra fuera de los horarios permitidos ' + this.editItem.inicio_dia + '-' + this.editItem.fin_dia + ' a ' + this.editItem.inicio_tarde + '-' + this.editItem.fin_tarde
                    this.colorSnak = 'info'
                    this.snackbar = true
                  } else {
                    this.dialogConfirm = true
                    this.salasConfirm.pop()
                    this.createEvent = {
                      name: `${procedimiento} #${this.events.length}`,
                      color: this.rndElement(this.colors),
                      start: this.createStart,
                      end: valid,
                      timed: true,
                      procedimiento: procedimiento,
                      id: '',
                      tiempoSala: this.cita.procedimiento.duracion.tiempo_sala,
                      tiempoNotario: this.cita.procedimiento.duracion.tiempo_notario,
                    }
                    this.confirmNew(this.createEvent, 'new')

                    // this.events.push(this.createEvent)
                  }
                }
              } else {
                this.text = 'Debe Seleccionar un notario para visualizar su agenda y agregar citas'
                this.colorSnak = 'info'
                this.snackbar = true
              }
            }
          }
        }
      },
      extendBottom (event) {
        this.createEvent = event
        this.createStart = event.start
        this.extendOriginal = event.end
      },
      mouseMove (tms) {
        const mouse = this.toTime(tms)
        if (this.dragEvent && this.dragTime !== null) {
          this.createStart = mouse.toISOString().substring(0, 16).replace('T', ' ')

          var valid = this.validHora(this.createStart)

          if (valid === 0) {
            this.text = 'La hora seleccionada se encuentra fuera de los horarios permitidos ' + this.editItem.inicio_dia + '-' + this.editItem.fin_dia + ' a ' + this.editItem.inicio_tarde + '-' + this.editItem.fin_tarde
            this.colorSnak = 'info'
            this.snackbar = true
          } else {
            // this.dialogConfirm = true
            // console.log('this.dragEvent:', this.dragEvent)
            this.dragEvent.start = this.createStart
            this.dragEvent.end = valid
            this.createEvent1 = {
              start: this.createStart,
              end: valid,
            }
          }
        }
      },
      endDrag () {
        if (this.dragEvent !== null && this.createEvent1 != null) {
          this.salasConfirm.pop()
          this.dialogConfirm = true
          this.confirmUpdate(this.createEvent1, 'update')
        }
        this.dragTime = null
        // this.dragEvent = null
        // this.createEvent = null
        // this.createStart = null
        this.extendOriginal = null
      },
      cancelDrag () {
        if (this.createEvent) {
          if (this.extendOriginal) {
            this.createEvent.end = this.extendOriginal
          } else {
            const i = this.events.indexOf(this.createEvent)
            if (i !== -1) {
              this.events.splice(i, 1)
            }
          }
        }

        this.createEvent = null
        this.createStart = null
        this.dragTime = null
        this.dragEvent = null
      },
      roundTime (time, down = true) {
        const roundTo = 15 // minutes
        const roundDownTime = roundTo * 60 * 1000

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },
      toTime (tms) {
        // return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
        tms.month = tms.month - 1
        // tms.hour = tms.hour - 4
        if (tms.minute === 0) {
          tms.minute = 0
        } else if (tms.minute <= 5) {
          tms.minute = 5
        } else if (tms.minute <= 10) {
          tms.minute = 10
        } else if (tms.minute <= 15) {
          tms.minute = 15
        } else if (tms.minute <= 20) {
          tms.minute = 20
        } else if (tms.minute <= 25) {
          tms.minute = 25
        } else if (tms.minute <= 30) {
          tms.minute = 30
        } else if (tms.minute <= 35) {
          tms.minute = 35
        } else if (tms.minute <= 40) {
          tms.minute = 40
        } else if (tms.minute <= 45) {
          tms.minute = 45
        } else if (tms.minute <= 50) {
          tms.minute = 50
        } else if (tms.minute <= 55) {
          tms.minute = 55
        } else {
          tms.hour = tms.hour + 1
          tms.minute = 0
        }
        return new Date(Date.UTC(tms.year, tms.month, tms.day, tms.hour, tms.minute))
      },
      getEventColor (event) {
        const rgb = parseInt(event.color.substring(1), 16)
        const r = (rgb >> 16) & 0xFF
        const g = (rgb >> 8) & 0xFF
        const b = (rgb >> 0) & 0xFF

        return event === this.dragEvent
          ? `rgba(${r}, ${g}, ${b}, 0.7)`
          : event === this.createEvent
            ? `rgba(${r}, ${g}, ${b}, 0.7)`
            : event.color
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      rndElement (arr) {
        return arr[this.rnd(0, arr.length - 1)]
      },
      getNotarios () {
        this.getSalas()
        if (this.id !== 0) {
          this.notarioId = false
          this.findAgendaByIdUSer(this.id)
        } else {
          if (localStorage.getItem('role') === 'ROLE_NOTARIO') {
            this.findAgendaByEmail()
          } else {
            this.notarioId = true
            this.notarios = []
            UsuariosApi.getAllNotarios()
              .then(notarios => {
                this.notarios = notarios
                if (notarios.length === 1) {
                  this.notario = notarios[0].id
                  this.findAgendaByIdUSer(this.notario)
                }
              })
              .catch(error => console.log(error))
          }
        }
      },
      getSalas () {
        MaestrosSalasApi.getAllSalas()
          .then(sala => {
            this.salasConfirm = sala
            this.salas = sala
            this.salas.push({
              id: 0,
              nombre: 'Todas',
            })
          })
          .catch(error => console.log(error))
      },
      findCitaByAgendaSala (i) {
        if (i === 0) {
          AgendaApi.getCitasAgenda(this.editItem.id)
            .then(response => {
              this.editItem.citas = response
              this.updateEvents(this.editItem.citas)
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          AgendaApi.getCitasSala(i, this.editItem.id)
            .then(response => {
              this.editItem.citas = response
              this.updateEvents(this.editItem.citas)
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      findAgendaByIdUSer (i) {
        AgendaApi.getAgendaByIdUser(i)
          .then(response => {
            this.editItem.id = response.id
            this.editItem.dates = response.dates
            this.editItem.days = response.days
            this.editItem.inicio_dia = response.inicio_dia
            this.editItem.fin_dia = response.fin_dia
            this.editItem.inicio_tarde = response.inicio_tarde
            this.editItem.fin_tarde = response.fin_tarde

            this.editItem.days.forEach(element => {
              var index = this.weekdays.indexOf(parseInt(element.days))
              if (index > 0) {
                this.weekdays.splice(index, 1)
              }
            })
            this.updateEvents(response.citas)
          })
          .catch(error => {
            console.log(error)
          })
      },
      findAgendaByEmail () {
        AgendaApi.getAgendaByEmail()
          .then(response => {
            this.editItem.id = response.id
            this.editItem.dates = response.dates
            this.editItem.days = response.days
            this.editItem.inicio_dia = response.inicio_dia
            this.editItem.fin_dia = response.fin_dia
            this.editItem.inicio_tarde = response.inicio_tarde
            this.editItem.fin_tarde = response.fin_tarde

            this.editItem.days.forEach(element => {
              var index = this.weekdays.indexOf(parseInt(element.days))
              if (index > 0) {
                this.weekdays.splice(index, 1)
              }
            })
            // this.updateEvents(response.citas)
          })
          .catch(error => {
            console.log(error)
          })
      },
      updateEvents (citas) {
        const events = []

        for (let i = 0; i < citas.length; i++) {
          const first = String(citas[i].inicio.date).substring(0, 16)
          const second = String(citas[i].fin.date).substring(0, 16)
          events.push({
            id: citas[i].id,
            name: citas[i].procedimiento,
            start: first,
            end: second,
            tiempoSala: citas[i].tiempo_sala,
            tiempoNotario: citas[i].tiempo_notario,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: true,
          })
        }

        this.events = events
      },
      validHora (hora) {
        var tiempoSala = 0
        if (this.dragEvent !== null && this.createEvent1 != null) {
          tiempoSala = this.dragEvent.tiempoSala
        } else {
          tiempoSala = this.cita.procedimiento.duracion.tiempo_sala
        }
        // var tiempoNotario = 10
        // var tiempoDiff = tiempoSala - tiempoNotario
        var inicioDia = this.editItem.inicio_dia
        var finDia = this.editItem.fin_dia
        var inicioTarde = this.editItem.inicio_tarde
        var finTarde = this.editItem.fin_tarde
        var fecha = hora.substring(0, 10)
        var horaInicio = hora.substring(11, 16)
        var horaSplit = horaInicio.split(':')

        var sumMinutes = parseInt(horaSplit[1]) + tiempoSala

        if (sumMinutes >= 60) {
          sumMinutes = sumMinutes - 60
          horaSplit[0] = parseInt(horaSplit[0]) + 1
        }

        var horaFin = horaSplit[0] + ':' + sumMinutes
        this.endStart = fecha + ' ' + horaFin

        var countHour = 0

        if (inicioDia !== null && finDia !== null) {
          inicioDia = inicioDia.split(':')
          finDia = finDia.split(':')
          if (parseInt(horaSplit[0]) > parseInt(inicioDia[0]) &&
            parseInt(horaSplit[0]) < parseInt(finDia[0])) {
            countHour++
          } else {
            if (parseInt(horaSplit[0]) === parseInt(inicioDia[0])) {
              if (parseInt(horaSplit[1]) >= parseInt(inicioDia[1])) {
                countHour++
              }
            }

            if (parseInt(horaSplit[0]) === parseInt(finDia[0])) {
              if (parseInt(horaSplit[1]) < parseInt(finDia[1])) {
                countHour++
              }
            }
          }
        }

        if (inicioTarde !== null && finTarde !== null) {
          inicioTarde = inicioTarde.split(':')
          finTarde = finTarde.split(':')
          if (parseInt(horaSplit[0]) > parseInt(inicioTarde[0]) &&
            parseInt(horaSplit[0]) < parseInt(finTarde[0])) {
            countHour++
          } else {
            if (parseInt(horaSplit[0]) === parseInt(inicioTarde[0])) {
              if (parseInt(horaSplit[1]) >= parseInt(inicioTarde[1])) {
                countHour++
              }
            }

            if (parseInt(horaSplit[0]) === parseInt(finTarde[0])) {
              if (parseInt(horaSplit[1]) < parseInt(finTarde[1])) {
                countHour++
              }
            }
          }
        }
        if (countHour === 1) {
          return this.endStart
        } else {
          return 0
        }
      },
      confirmNew (event, action) {
        this.someData = action
        let edit = false

        const comparar = event.start.substring(0, 10)
        // this.createStart = this.roundTime(mouse)
        this.editItem.dates.forEach(element => {
          if (element.dia === comparar) {
            edit = true
          }
        })

        if (edit) {
          this.text = 'El día seleccionado (' + comparar + ') está restingido'
          this.colorSnak = 'info'
          this.snackbar = true
          this.dialogConfirm = false
        }
        var valid = this.validHora(event.start)
        if (valid === 0) {
          this.text = 'La hora seleccionada se encuentra fuera de los horarios permitidos ' + this.editItem.inicio_dia + '-' + this.editItem.fin_dia + ' a ' + this.editItem.inicio_tarde + '-' + this.editItem.fin_tarde
          this.colorSnak = 'info'
          this.snackbar = true
          this.dialogConfirm = false
        }
      },
      confirmUpdate (event, action) {
        this.someData = action
        let edit = false

        const comparar = event.start.substring(0, 10)
        // this.createStart = this.roundTime(mouse)
        this.editItem.dates.forEach(element => {
          if (element.dia === comparar) {
            edit = true
          }
        })

        if (edit) {
          this.text = 'El día seleccionado (' + comparar + ') está restingido'
          this.colorSnak = 'info'
          this.snackbar = true
          this.dialogConfirm = false
        }
        var valid = this.validHora(event.start)
        if (valid === 0) {
          this.text = 'La hora seleccionada se encuentra fuera de los horarios permitidos ' + this.editItem.inicio_dia + '-' + this.editItem.fin_dia + ' a ' + this.editItem.inicio_tarde + '-' + this.editItem.fin_tarde
          this.colorSnak = 'info'
          this.snackbar = true
          this.dialogConfirm = false
        }
      },
      closeConfirm () {
        this.$refs.form.resetValidation()
        this.dialogConfirm = false
        this.salas.push({
          id: 0,
          nombre: 'Todas',
        })
        this.findAgendaByIdUSer(this.notario)
      },
      saveConfirm () {
        if (this.$refs.form.validate()) {
          if (this.someData === 'new') {
            var data = {
              sala: this.salaConfirm,
              expediente: this.cita.id,
              agenda: this.editItem.id,
              inicio: this.createEvent.start,
              fin: this.createEvent.end,
              tiempo_sala: this.cita.procedimiento.duracion.tiempo_sala,
              tiempo_notario: this.cita.procedimiento.duracion.tiempo_notario,
              procedimiento: this.createEvent.procedimiento,
              cliente: this.cliente,
            }

            AgendaApi.addCitas(data)
              .then(response => {
                if (response.status === 201) {
                  this.text = response.message
                  this.colorSnak = 'info'
                  this.snackbar = true
                } else {
                  this.text = response.message
                  this.colorSnak = 'success'
                  this.snackbar = true
                  this.createEvent.id = response.id
                  this.events.push(this.createEvent)
                }
                this.createEvent = null
                this.createEvent1 = null
              })
              .catch(error => {
                this.text = error
                this.colorSnak = 'error'
                this.snackbar = true
                this.createEvent = null
                this.createEvent1 = null
              })
          } else {
            var datos = {
              id: this.dragEvent.id,
              sala: this.salaConfirm,
              inicio: this.dragEvent.start,
              fin: this.dragEvent.end,
            }

            AgendaApi.updateCitas(datos)
              .then(response => {
                if (response.status === 201) {
                  this.text = response.message
                  this.colorSnak = 'info'
                  this.snackbar = true
                  this.createEvent = null
                  this.createEvent1 = null
                  this.getCitasClientes(this.cliente)
                } else {
                  this.text = response.message
                  this.colorSnak = 'success'
                  this.snackbar = true
                  this.createEvent = null
                  this.createEvent1 = null
                }
              })
              .catch(error => {
                this.text = error
                this.colorSnak = 'error'
                this.snackbar = true
                this.createEvent = null
                this.createEvent1 = null
              })
          }
          this.dialogConfirm = false
          this.dragEvent = null
          this.$refs.form.resetValidation()

          this.salas.push({
            id: 0,
            nombre: 'Todas',
          })
        }
      },
      getCitasClientes (i) {
        this.getSalas()
        AgendaApi.getCitasClienteById(i)
          .then(response => {
            this.updateEvents(response)
          })
          .catch(error => {
            console.log(error)
          })
      },

      intervalStyle (interval) {
        const inactive = interval.weekday === 0 ||
          interval.weekday === 6 ||
          interval.hour < 8 ||
          interval.hour >= 18
        const startOfHour = interval.minute === 0
        const dark = this.dark
        const mid = dark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

        return {
          backgroundColor: inactive ? (dark ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.05)') : undefined,
          borderTop: startOfHour ? undefined : '1px dashed ' + mid,
        }
      },
    },
  }
</script>
<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}

button.v-btn.v-btn--depressed.v-btn--fab.v-btn--round.theme--light.v-size--default.transparent {
  span.v-btn__content {
    color: black
  }

  &:hover {
    background: grey;
    color: white
  }
}
</style>
